import { USERS_MODULE } from '../modules/users'
import { CLINICS_MODULE } from '../modules/clinics'
import { AUTHS_MODULE } from '../modules/auth'
import { TESTS_MODULE } from '../modules/tests'
import { PATIENT_PARAMS_MODULE, PATIENTS_MODULE } from '../modules/patients'
import { FILES_MODULE } from '../modules/files'
import { SCRIPTS_MODULE } from '../modules/scripts'
import { SCRIPT_EXECUTIONS_MODULE } from '../modules/script-executions'
import { ANALYSIS_MODULE } from '../modules/analysis'
import { RECEPTIVENESS_FILES_MODULE } from '../modules/receptiveness-files/container'

const apiURL = process.env.REACT_APP_BACKEND_URL

export const moduleURLs = {
  [AUTHS_MODULE]: `${apiURL}`,
  [USERS_MODULE]: `${apiURL}/users`,
  [CLINICS_MODULE]: `${apiURL}/clinics`,
  [TESTS_MODULE]: `${apiURL}/tests`,
  [PATIENTS_MODULE]: `${apiURL}/patients`,
  [PATIENT_PARAMS_MODULE]: `${apiURL}/patient-params`,
  [FILES_MODULE]: `${apiURL}/files`,
  [SCRIPT_EXECUTIONS_MODULE]: `${apiURL}/script-executions`,
  [SCRIPTS_MODULE]: `${apiURL}/scripts`,
  [ANALYSIS_MODULE]: `${apiURL}/analysis`,
  [RECEPTIVENESS_FILES_MODULE]: `${apiURL}/receptivenessfiles`
}
