import {emptyList, ItemList} from '../../../common/models/ItemList'
import {Observable, of} from 'rxjs'
import {Container, IInit} from '../../../common/container/Container'
import {HTTP_CLIENT_KEY, IHTTPClient} from '../../../common/api/HTTPClient'
import {IStatusService} from '../../../common/status/StatusService'
import {STATUS_SERVICE_KEY} from '../../../container/app'
import {Query} from '../../../common/api/Query'
import {Clinic, ClinicQuery} from '../models/Clinic'
import {ClinicContainerConfig} from '../container'
import {ClinicDTO, toModel} from '../models/ClinicDTO'
import {prepareURL} from '../../../common/api/http-helpers'
import {catchError, map} from 'rxjs/operators'

export interface IClinicApi extends IInit {
    getByID(id: string): Observable<Clinic | undefined>

    getFilteredList(q: Query<ClinicQuery>): Observable<ItemList<Clinic>>

    getAllClinics(): Observable<ItemList<Clinic>>

    add(e: ClinicDTO): Observable<Clinic | undefined>

    update(e: ClinicDTO): Observable<Clinic | undefined>

    delete(id: string): Observable<boolean>
}

export class ClinicApi implements IClinicApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as ClinicContainerConfig).moduleFullUrl
    }

    add(e: ClinicDTO): Observable<Clinic | undefined> {
        return this._httpClient.post<Clinic>({url: this._url, body: e}).pipe(
            map<ClinicDTO, Clinic>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({url: this._url + '/' + id}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    getByID(id: string): Observable<Clinic | undefined> {
        return this._httpClient.get<Clinic>({url: `${this._url}/${id}`}).pipe(
            map<ClinicDTO, Clinic>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    getFilteredList(q: Query<ClinicQuery>): Observable<ItemList<Clinic>> {
        return this._httpClient.get<ItemList<Clinic>>({url: prepareURL(this._url, q)}).pipe(
            map<ItemList<ClinicDTO>, ItemList<Clinic>>((dto) => {
                const itemList = emptyList<Clinic>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(emptyList<Clinic>())
            })
        )
    }

    getAllClinics(): Observable<ItemList<Clinic>> {
        return this._httpClient.post<ItemList<Clinic>>({url: `${this._url}/getAllClinics`}).pipe(
            map<ItemList<ClinicDTO>, ItemList<Clinic>>((dto) => {
                const itemList = emptyList<Clinic>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(emptyList<Clinic>())
            })
        )
    }

    update(e: ClinicDTO): Observable<Clinic | undefined> {
        return this._httpClient.put<Clinic>({url: this._url, body: e}).pipe(
            map<ClinicDTO, Clinic>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }
}
