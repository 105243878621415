import { Box, Button, Grid, InputLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Analysis, AnalysisDTO } from '../../modules/analysis/models/Analysis'
import React, { useEffect, useState } from 'react'
import { getAnalysisContainer } from '../../container/analysis-module'
import { AnalysisService } from '../../modules/analysis/services/AnalysisService'
import { ANALYSIS_SERVICE_KEY } from '../../modules/analysis'
import { useStyles } from './Receptiveness.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { downloadFile } from '../../common/files/file'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { getFileContainer } from '../../container/file-module'
import { v4 as uuidv4 } from 'uuid'
import styles from './ThirdStep.module.css'

type ThirdStepProps = {
  analysis: Analysis
  resetSteps: () => void
}

const analysisService = getAnalysisContainer().get<AnalysisService>(ANALYSIS_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

const stepsLabel = ['initializingAlgorithm', 'downloadResults', 'receptivenessError']

export const ThirdStep = (props: ThirdStepProps) => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })
  const [analysis, setAnalysis] = useState<AnalysisDTO>(Object.assign({ ...props.analysis.toDTO() }, { zipFileID: uuidv4() }))
  const [success, setSuccess] = useState<boolean | undefined>(undefined)
  const [labels, setLabels] = useState<string[]>([stepsLabel[0]])
  const [errorLog, setErrorLog] = useState<string>('')

  useEffect(() => {
    console.log("envio: ", analysis)
    analysisService.add(analysis).subscribe((res) => {
      if (res?.zipFileID) {
        res && setAnalysis(res)
        setLabels([...labels, res && res.zipFileID ? stepsLabel[1] : stepsLabel[2]])
        setSuccess(true)
      } else {
        setLabels([...labels, res && res.zipFileID ? stepsLabel[1] : stepsLabel[2]])
        setErrorLog(res)
        setSuccess(false)
      }
    })
  }, [])

  const downloadFiles = () =>
    analysis.zipFileID && fileService.getByID(analysis.zipFileID).subscribe((res) =>
      res && downloadFile(res.name, res.mimeType, res.data))

  return (
    <Box margin={'auto'}>
      <Box margin={'auto'} mb={3} className={styles.box}>
        <ol>
          {labels.map((l) => (
            <li>
              <InputLabel className={styles.label}>{t(l)}</InputLabel>
            </li>
          ))}
        </ol>
      </Box>
      <Grid item xs={12}>
        {success && (
          <Button
            className={classes.button}
            onClick={downloadFiles}
            variant={'contained'}
            style={{ float: 'none' }}
          >
            {t('download')}
          </Button>
        )}
        {success === false && (
          <>
          {errorLog && (
            <p style={{fontSize: "15px", marginTop: "3px", marginBottom: "20px", color: "#a11616"}}>*{t(errorLog)}</p>
          )}
          <Button
            className={classes.button}
            onClick={props.resetSteps}
            variant={'contained'}
            style={{ float: 'none' }}
          >
            {t('backToHome')}
          </Button>
          </>
        )}
      </Grid>
    </Box>
  )
}
