import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FormActions } from '../../common/utils/form-generation'
import { Box, Grid, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from '../../common/utils/form-generation/useForm'
import { emptyScriptDTO, ScriptDTO } from '../../modules/scripts/models/Script'
import { forkJoin, Observable } from 'rxjs'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { File as F, FileDTO } from '../../modules/files/models/File'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { v4 as uuidv4 } from 'uuid'
import { dataToBase64, downloadFile } from '../../common/files/file'
import { getScriptContainer } from '../../container/scripts-module'
import { ScriptService } from '../../modules/scripts/services/ScriptService'
import { SCRIPT_SERVICE_KEY } from '../../modules/scripts'
import { AppTable, Field } from '../../components/table'
import { map } from 'rxjs/operators'
import { Actions } from '../../components/table/types'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import uploadIcon from '../../assets/table-icons/upload-icon.svg'
import { Alert } from '@material-ui/lab'

type FileType = {
  type: string
  file: F | undefined
}

const excludedKeys = ['id', 'version']

const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const scriptService = getScriptContainer().get<ScriptService>(SCRIPT_SERVICE_KEY)

export const Form = () => {
  const { t } = useTranslation()

  const [isError, setIsError] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [originalData, setOriginalData] = useState<ScriptDTO>(emptyScriptDTO())
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<string>('')
  const [items, setItems] = useState<FileType[]>([])
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  useEffect(() => {
    scriptService.getCurrentScript().subscribe((res) => {
      setIsEditing(!!res)
      if (!res) {
        return
      }
      const newData = res.toDTO()
      setData(newData)
      setOriginalData(newData)
      const entries = Object.entries(newData).filter((k) => !excludedKeys.includes(k[0]))
      const values = entries.map((k) => k[1])
      const keys = entries.map((k) => k[0])
      getFiles(keys, values).subscribe((fl) => setItems(fl))
    })
  }, [])

  useEffect(() => {
    items.length && setIsError(items.some((i) => !i.file?.name?.toLowerCase().startsWith(i.type)))
  }, [items])

  const handleFileInput = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
    setIsSuccess(false)
    const fl = (event.target as HTMLInputElement).files
    if (!fl?.length) {
      return
    }

    const index = items.findIndex((f) => f.type === key)
    if (index === -1) {
      return
    }
    const data = await dataToBase64(fl[0])
    const result = [...items]
    result.splice(index, 1, { type: key, file: new F(convertToFile(fl[0], data)) })
    setItems(result)
  }

  const convertToFile = (f: File, data: string): FileDTO => ({
    id: uuidv4(),
    name: f.name,
    data,
    size: f.size,
    mimeType: f.type,
    extension: f.type.split('/')[1],
    ownerID: authService.get().id,
  })

  const getFiles = (keys: string[], ids: string[]): Observable<FileType[]> =>
    forkJoin(ids.map((id, i) =>
      fileService.getCurrentScriptByID(id).pipe(
        map((f) => {
          return ({
            type: keys[i],
            file: f,
          })
        }),
      ),
    )) as unknown as Observable<FileType[]>

  const addFiles = (fl: FileDTO[]): Observable<F[]> =>
    forkJoin(fl.map((f) => fileService.add(f))) as unknown as Observable<F[]>

  const deleteFiles = (ids: string[]): Observable<boolean[]> =>
    forkJoin(ids.map((id) => fileService.delete(id))) as unknown as Observable<boolean[]>

  const changeFile = (f: FileType) => {
    setSelectedFile(f.type)
    fileInputRef.current?.click()
  }

  const download = (f: FileType) => f.file && downloadFile(f.file.name, f.file.mimeType, f.file.data)

  const hideFile = (f: FileType) => !f.file?.name

  const { handleChange, handleSubmit, data, setData, errors } = useForm<ScriptDTO>({
    onSubmit: () => {
      const fl: FileDTO[] = []
      items.forEach((i) => i.file && fl.push(i.file.toDTO()))
      if (!fl.length) {
        return
      }
      addFiles(fl).subscribe(() => {
        const newData: ScriptDTO = Object.assign({ ...data },
          ...items.map((i) => ({ [i.type]: i.file?.id })),
        )

        setIsSuccess(true)
        if (!isEditing) {
          scriptService.create(newData).subscribe(() => setIsEditing(true))
          return
        }
        const oldIDs = Object.entries(originalData)
          .filter((k) => !excludedKeys.includes(k[0]))
          .map((v) => v[1])
        const newIDs = items.map((i) => i.file?.id)
        scriptService.update(newData).subscribe(() =>
          deleteFiles(oldIDs.filter((o) => newIDs.every((n) => n !== o))).subscribe(),
        )
      })
    },

    initialValues: emptyScriptDTO(),
  })

  const fields: Field<FileType>[] = [
    {
      label: t('file'),
      name: 'type',
      renderFunc: (f, i) => t(i.type),
    },
    {
      label: t('name'),
      name: 'file',
      renderFunc: (f, i) => i.file?.name || '',
    },
  ]

  const actions: Actions<FileType> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: changeFile,
        icon: uploadIcon,
        label: t('Upload'),
      },
      {
        handler: download,
        icon: downloadIcon,
        label: t('Download'),
        hidden: hideFile,
      },
    ],
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            error={errors['version'] !== undefined}
            fullWidth
            variant={'outlined'}
            id='version'
            type={'number'}
            label={t('version')}
            onChange={(event) => handleChange('version', +event.target.value)}
            value={data && data.version}
            helperText={errors['version']}
            InputProps={{ inputProps: { min: 0, step: 0.01 } }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <AppTable
          fields={fields}
          items={items}
          rowKeyField={'type'}
          actions={actions}
        />
        <input
          onChange={(event) => handleFileInput(event, selectedFile)}
          multiple={false}
          ref={fileInputRef}
          type='file'
          hidden
        />
      </Grid>

      {isError && (
        <Box mt={3}>
          <Alert
            severity='error'> {t('someFilesError')}: {items.filter((i) => !i.file?.name.toLowerCase().startsWith(i.type))
            .map((i) => i.type).join(', ')} </Alert>
        </Box>
      )}
      {isSuccess && (
        <Box mt={3}>
          <Alert
            severity='success'>{t('allChangesSaved')}</Alert>
        </Box>
      )}
      <FormActions disabled={isError} />
    </form>
  )
}