import { GenderType } from '../../users/enums/GenderType'
import { v4 as uuidv4 } from 'uuid'
import { BoolQueryParam } from '../../../common/api/Query'
import { PatientLanguage } from '../../users/enums/Language'

export interface PatientDTO {
  id: string
  sampleName: string
  bioarrayCode: string
  idPatient: string
  firstName: string
  lastName: string
  dob?: Date
  creationDate: Date
  gender: GenderType
  geneticDiseaseHistory: string
  isPublic: boolean
  clinicID: string
  language: string
}

export class Patient {

  private _id: string
  private _sampleName: string
  private _bioarrayCode: string
  private _idPatient: string
  private _firstName: string
  private _lastName: string
  private _dob?: Date
  private _creationDate: Date
  private _gender: GenderType
  private _geneticDiseaseHistory: string
  private _isPublic: boolean
  private _clinicID: string
  private _language: string

  constructor(p: PatientDTO) {
    this._id = p.id
    this._sampleName = p.sampleName
    this._bioarrayCode = p.bioarrayCode
    this._idPatient = p.idPatient
    this._firstName = p.firstName
    this._lastName = p.lastName
    this._dob = p.dob
    this._creationDate = p.creationDate
    this._gender = p.gender
    this._geneticDiseaseHistory = p.geneticDiseaseHistory
    this._isPublic = p.isPublic
    this._clinicID = p.clinicID
    this._language = p.language
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get bioarrayCode(): string {
    return this._bioarrayCode
  }

  set bioarrayCode(value: string) {
    this._bioarrayCode = value
  }

  get idPatient(): string {
    return this._idPatient
  }

  set idPatient(value: string) {
    this._idPatient = value
  }

  get firstName(): string {
    return this._firstName
  }

  set firstName(value: string) {
    this._firstName = value
  }

  get lastName(): string {
    return this._lastName
  }

  set lastName(value: string) {
    this._lastName = value
  }

  get fullName(): string {
    return this._firstName + ' ' + this._lastName
  }

  get dob(): Date | undefined {
    return this._dob
  }

  set dob(value: Date | undefined) {
    this._dob = value
  }

  get creationDate(): Date {
    return this._creationDate;
  }

  set creationDate(value: Date) {
    this._creationDate = value;
  }

  get gender(): GenderType {
    return this._gender
  }

  set gender(value: GenderType) {
    this._gender = value
  }


  get geneticDiseaseHistory(): string {
    return this._geneticDiseaseHistory
  }

  set geneticDiseaseHistory(value: string) {
    this._geneticDiseaseHistory = value
  }

  get isPublic(): boolean {
    return this._isPublic
  }

  set isPublic(value: boolean) {
    this._isPublic = value
  }

  get sampleName(): string {
    return this._sampleName
  }

  set sampleName(value: string) {
    this._sampleName = value
  }

  get clinicID(): string {
    return this._clinicID;
  }

  set clinicID(value: string) {
    this._clinicID = value;
  }

  get language(): string {
    return this._language
  }

  toDTO(): PatientDTO {
    return {
      id: this.id,
      sampleName: this.sampleName,
      bioarrayCode: this.bioarrayCode,
      idPatient: this.idPatient,
      firstName: this.firstName,
      lastName: this.lastName,
      dob: this.dob,
      creationDate: this.creationDate,
      gender: this.gender,
      geneticDiseaseHistory: this.geneticDiseaseHistory,
      isPublic: this.isPublic,
      clinicID: this.clinicID,
      language: this.language,
    }
  }
}

export function toModel(d: PatientDTO): Patient {
  return new Patient(d)
}

export function emptyPatientDTO(): PatientDTO {
  return {
    id: uuidv4(),
    bioarrayCode: '',
    sampleName: '',
    idPatient: '',
    firstName: '',
    lastName: '',
    dob: undefined,
    creationDate: new Date(),
    gender: GenderType.Female,
    geneticDiseaseHistory: '',
    isPublic: true,

    clinicID: '',
    language: PatientLanguage.Spanish
  }
}

export interface PatientQuery {
  haveTest: BoolQueryParam
  clinics: string[]
  ids: string
  idPatient: string
  sampleName: string
  idBioarray: string
  clinicName: string
  firstName: string
  lastName: string
  csvDate: Date
  sampleNames: string[]
}
