import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { Container, IInit } from 'common/container/Container'
import { Observable, of } from 'rxjs'
import { ReceptivenessFileContainerConfig } from '../container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { ReceptivenessFile, ReceptivenessFileDTO, toModel } from '../models/ReceptivenessFile'

export interface IReceptivenessFileApi extends IInit {
  getByID(id: string): Observable<ReceptivenessFile | undefined>
  getFilteredList(q: Query<ReceptivenessFile>): Observable<ItemList<ReceptivenessFile>>
  add(e: ReceptivenessFileDTO): Observable<ReceptivenessFile | undefined>
  update(e: ReceptivenessFileDTO): Observable<ReceptivenessFile | undefined>
  delete(id: string): Observable<boolean>
  getCurrentScriptByID(id: string): Observable<ReceptivenessFile | undefined>
}

export class ReceptivenessFileApi implements IReceptivenessFileApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ReceptivenessFileContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<ReceptivenessFile | undefined> {
    return this._httpClient.get<ReceptivenessFile>({ url: `${this._url}/${id}` }).pipe(
      map<ReceptivenessFileDTO, ReceptivenessFile>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<ReceptivenessFile>): Observable<ItemList<ReceptivenessFile>> {
    return this._httpClient.get<ItemList<ReceptivenessFile>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<ReceptivenessFileDTO>, ItemList<ReceptivenessFile>>((dto) => {
        const itemList = emptyList<ReceptivenessFile>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<ReceptivenessFile>())
      })
    )
  }

  add(e: ReceptivenessFileDTO): Observable<ReceptivenessFile | undefined> {
    return this._httpClient.post<ReceptivenessFile>({ url: this._url, body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: ReceptivenessFileDTO): Observable<ReceptivenessFile | undefined> {
    return this._httpClient.put<ReceptivenessFile>({ url: this._url, body: e }).pipe(
      map<ReceptivenessFileDTO, ReceptivenessFile>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getCurrentScriptByID(id: string): Observable<ReceptivenessFile | undefined> {
    return this._httpClient.get<ReceptivenessFile>({ url: `${this._url}/currentscript/${id}` }).pipe(
      map<ReceptivenessFileDTO, ReceptivenessFile>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
