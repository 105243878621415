import { v4 as uuidv4 } from 'uuid'
import { Clinic } from './Clinic'

export interface ClinicDTO {
  id: string | undefined
  idClinic: string | undefined
  name: string
  address: string
  cp: string
  locality: string
  cif: string
  odooNumber: string | undefined
  email: string
  phone: string
  patients: string[]
  entryDate: Date
  usedCredits: number
  remainingCredits: number
  kitCode: string
  receptiveness: boolean
  disabled: boolean
  header: string
  signature: string
  roleSignature: string
  signedBy: string
}

export function emptyClinicDTO(): ClinicDTO {
  return {
    id: uuidv4(),
    idClinic: '',
    name: '',
    address: '',
    cp: '',
    locality: '',
    cif: '',
    odooNumber: '',
    email: '',
    phone: '',
    patients: [],
    entryDate: new Date(),
    usedCredits: 0,
    remainingCredits: 0,
    receptiveness: false,
    kitCode: '',
    disabled: false,
    header: '',
    signature: '',
    roleSignature: '',
    signedBy: '',
  }
}

export function fromModel(c: Clinic): ClinicDTO {
  return {
    id: c.id,
    idClinic: c.idClinic,
    name: c.name,
    address: c.address,
    cp: c.cp,
    locality: c.locality,
    cif: c.cif,
    odooNumber: c.odooNumber,
    email: c.email,
    phone: c.phone,
    patients: c.patients,
    entryDate: c.entryDate,
    usedCredits: c.usedCredits,
    remainingCredits: c.remainingCredits,
    kitCode: c.kitCode,
    receptiveness: c.receptiveness,
    disabled: c.disabled,
    header: c.header,
    signature: c.signature,
    roleSignature: c.roleSignature,
    signedBy: c.signedBy,
  }
}

export function toModel(c: ClinicDTO): Clinic {
  return new Clinic(c)
}
