import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { ScriptExecutionService } from '../modules/script-executions/services/ScriptExecutionService'
import { ScriptExecutionApi } from '../modules/script-executions/api/ScriptExecutionApi'
import { ScriptProps } from '../modules/scripts/container'
import {
  SCRIPT_EXECUTION_API_KEY,
  SCRIPT_EXECUTION_SERVICE_KEY,
  SCRIPT_EXECUTIONS_MODULE,
} from '../modules/script-executions'

let container: Container

function init(p: ScriptProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(SCRIPT_EXECUTION_API_KEY, new ContainerItem({ instance: new ScriptExecutionApi() }))
  items.set(
    SCRIPT_EXECUTION_SERVICE_KEY,
    new ContainerItem({
      instance: new ScriptExecutionService({ apiKey: SCRIPT_EXECUTION_API_KEY }),
    }),
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getScriptExecutionContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[SCRIPT_EXECUTIONS_MODULE],
      },
    })
  }

  return container
}