import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { Container, IInit } from 'common/container/Container'
import { Observable, of } from 'rxjs'
import { ScriptContainerConfig } from '../container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { catchError, map } from 'rxjs/operators'
import { Script, ScriptDTO, toModel as scriptToModel } from '../models/Script'

export interface IScriptApi extends IInit {
  getCurrentScript(): Observable<Script | undefined>

  create(p: ScriptDTO): Observable<Script | undefined>

  update(p: ScriptDTO): Observable<Script | undefined>
}

export class ScriptApi implements IScriptApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ScriptContainerConfig).moduleFullUrl
  }

  getCurrentScript(): Observable<Script | undefined> {
    return this._httpClient.get<Script>({ url: this._url }).pipe(
      map<ScriptDTO, Script>((dto) => scriptToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  create(p: ScriptDTO): Observable<Script | undefined> {
    return this._httpClient.post<Script>({ url: this._url, body: p }).pipe(
      map<ScriptDTO, Script>((dto) => scriptToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  update(p: ScriptDTO): Observable<Script | undefined> {
    return this._httpClient.put<Script>({ url: this._url, body: p }).pipe(
      map<ScriptDTO, Script>((dto) => scriptToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }


}
