export const TITLE_DASHBOARD: string = 'Bioarray'
export const TITLE_TEST_TYPES: string = 'testTypes'
export const TITLE_GENE_TYPES: string = 'geneTypes'
export const TITLE_USERS: string = 'users'
export const TITLE_PROFILE_USER: string = 'profile'
export const TITLE_PATIENTS: string = 'patients'
export const TITLE_CLINICS: string = 'clinics'
export const TITLE_REPORTS: string = 'reports'
export const TITLE_MATCHING: string = 'matching'
export const TITLE_PARAMETERS: string = 'parameters'
export const TITLE_TRANSLATIONS: string = 'translations'
export const TITLE_PATIENT: string = 'patient'
export const TITLE_CLINIC: string = 'clinics'
export const TITLE_TESTS: string = 'tests'
export const TITLE_SETTINGS: string = 'Settings'
export const TITLE_RECEPTIVENESS: string = 'receptivity'
export const TITLE_SCRIPT: string = 'Script'
export const TITLE_MICROBIOTA: string = 'microbiota'
export const TITLE_FUTURE_KITS: string = 'futureKits'
