import { BoolQueryParam } from '../../../common/api/Query'

export interface ScriptExecutionDTO {
  id: string
  date: Date
  clinicID: string
  userID: string
  success: boolean
  summaryFileID: string
  matrixFileID: string
  zipFileID: string
}

export class ScriptExecution {
  private readonly _id: string
  private readonly _date: Date
  private readonly _clinicID: string
  private readonly _userID: string
  private readonly _success: boolean
  private readonly _summaryFileID: string
  private readonly _matrixFileID: string
  private readonly _zipFileID: string

  constructor(p: ScriptExecutionDTO) {
    this._id = p.id
    this._date = p.date
    this._clinicID = p.clinicID
    this._userID = p.userID
    this._success = p.success
    this._summaryFileID = p.summaryFileID
    this._matrixFileID = p.matrixFileID
    this._zipFileID = p.zipFileID
  }

  get id(): string {
    return this._id
  }

  get date(): Date {
    return this._date
  }

  get clinicID(): string {
    return this._clinicID
  }

  get userID(): string {
    return this._userID
  }

  get success(): boolean {
    return this._success
  }

  get summaryFileID(): string {
    return this._summaryFileID
  }

  get matrixFileID(): string {
    return this._matrixFileID
  }

  get zipFileID(): string {
    return this._zipFileID
  }

  toDTO(): ScriptExecutionDTO {
    return {
      id: this.id,
      date: this.date,
      clinicID: this.clinicID,
      userID: this.userID,
      success: this.success,
      summaryFileID: this.summaryFileID,
      matrixFileID: this.matrixFileID,
      zipFileID: this.zipFileID,
    }
  }
}

export function toModel(d: ScriptExecutionDTO): ScriptExecution {
  return new ScriptExecution(d)
}

export interface ScriptExecutionQuery {
  date: Date
  success: BoolQueryParam
}