import { TestCycle } from '../../tests/enums/TestCycle'

export interface ResultPatientDataDTO {
  barcodeID: string
  sampleName: string
  biopsyMethod: string | undefined
  biopsyDate: Date | undefined
  biopsyNumber: number
  cycleType: TestCycle
  p4Injection: Date | undefined
  hcgInjection: Date | undefined
  lhDate: Date | undefined
  pPlus: string
  lhPlus: string
  hcgPlus: string
}

export class ResultPatientData {
  private _barcodeID: string
  private _sampleName: string
  private _biopsyMethod: string | undefined
  private _biopsyDate: Date | undefined
  private _biopsyNumber: number
  private _cycleType: TestCycle
  private _p4Injection: Date | undefined
  private _hcgInjection: Date | undefined
  private _lhDate: Date | undefined
  private _pPlus: string
  private _lhPlus: string
  private _hcgPlus: string

  constructor(p: ResultPatientDataDTO) {
    this._barcodeID = p.barcodeID
    this._sampleName = p.sampleName
    this._biopsyMethod = p.biopsyMethod
    this._biopsyDate = p.biopsyDate
    this._biopsyNumber = p.biopsyNumber
    this._cycleType = p.cycleType
    this._p4Injection = p.p4Injection
    this._hcgInjection = p.hcgInjection
    this._lhDate = p.lhDate
    this._pPlus = p.pPlus
    this._lhPlus = p.lhPlus
    this._hcgPlus = p.hcgPlus
  }

  get barcodeID(): string {
    return this._barcodeID
  }

  set barcodeID(value: string) {
    this._barcodeID = value
  }

  get sampleName(): string {
    return this._sampleName
  }

  set sampleName(value: string) {
    this._sampleName = value
  }

  get biopsyMethod(): string {
    return this._biopsyMethod || ''
  }

  set biopsyMethod(value: string) {
    this._biopsyMethod = value
  }

  get biopsyDate(): Date | undefined {
    return this._biopsyDate
  }

  set biopsyDate(value: Date | undefined) {
    this._biopsyDate = value
  }

  get biopsyNumber(): number {
    return this._biopsyNumber
  }

  set biopsyNumber(value: number) {
    this._biopsyNumber = value
  }

  get cycleType(): TestCycle {
    return this._cycleType
  }

  set cycleType(value: TestCycle) {
    this._cycleType = value
  }

  get p4Injection(): Date | undefined {
    return this._p4Injection
  }

  set p4Injection(value: Date | undefined) {
    this._p4Injection = value
  }

  get hcgInjection(): Date | undefined{
    return this._hcgInjection
  }

  set hcgInjection(value: Date | undefined) {
    this._hcgInjection = value
  }

  get lhDate(): Date | undefined{
    return this._lhDate
  }

  set lhDate(value: Date | undefined) {
    this._lhDate = value
  }

  get pPlus(): string {
    return this._pPlus
  }

  set pPlus(value: string) {
    this._pPlus = value
  }

  get lhPlus(): string {
    return this._lhPlus
  }

  set lhPlus(value: string) {
    this._lhPlus = value
  }

  get hcgPlus(): string {
    return this._hcgPlus
  }

  set hcgPlus(value: string) {
    this._hcgPlus = value
  }

  toDTO(): ResultPatientDataDTO {
    return {
      barcodeID: this.barcodeID,
      sampleName: this.sampleName,
      biopsyMethod: this.biopsyMethod,
      biopsyDate: this.biopsyDate,
      biopsyNumber: this.biopsyNumber,
      cycleType: this.cycleType,
      p4Injection: this.p4Injection,
      hcgInjection: this.hcgInjection,
      lhDate: this.lhDate,
      pPlus: this.pPlus,
      lhPlus: this.lhPlus,
      hcgPlus: this.hcgPlus,
    }
  }
}

export function toModel(d: ResultPatientDataDTO): ResultPatientData {
  return new ResultPatientData(d)
}
