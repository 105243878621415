import { v4 as uuidv4 } from 'uuid'

export interface ReceptivenessFileDTO {
  id: string
  name: string
  data: string
  size: number
  mimeType: string
  extension: string
  ownerID: string
  zipFileID: string
}

export class ReceptivenessFile {
  private readonly _id: string
  private readonly _name: string
  private readonly _data: string
  private readonly _size: number
  private readonly _mimeType: string
  private readonly _extension: string
  private readonly _ownerID: string
  private readonly _zipFileID: string

  constructor(p: ReceptivenessFileDTO) {
    this._id = p.id
    this._name = p.name
    this._data = p.data
    this._size = p.size
    this._mimeType = p.mimeType
    this._extension = p.extension
    this._ownerID = p.ownerID
    this._zipFileID = p.zipFileID
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get data(): string {
    return this._data
  }

  get size(): number {
    return this._size
  }

  get mimeType(): string {
    return this._mimeType
  }

  get extension(): string {
    return this._extension
  }

  get ownerID(): string {
    return this._ownerID
  }


  get zipFileID(): string {
    return this._zipFileID
  }

  toDTO(): ReceptivenessFileDTO {
    return {
      id: this.id,
      name: this.name,
      data: this.data,
      size: this.size,
      mimeType: this.mimeType,
      extension: this.extension,
      ownerID: this.ownerID,
      zipFileID: this.zipFileID,
    }
  }
}

export function toModel(d: ReceptivenessFileDTO): ReceptivenessFile {
  return new ReceptivenessFile(d)
}

export function emptyReceptivenessFileDTO(): ReceptivenessFileDTO {
  return {
    id: uuidv4(),
    name: '',
    data: '',
    size: 0,
    mimeType: '',
    extension: '',
    ownerID: '',
    zipFileID: '',
  }
}