import { Container, IInit } from 'common/container/Container'
import { ConnectableObservable, Observable, Subject } from 'rxjs'
import { multicast } from 'rxjs/operators'
import { emptyLoggedUserDTO, LoggedUserDTO, toModel } from '../models/LoggedUserDTO'
import { LoggedUser } from '../models/LoggedUser'
import { AuthDTO } from '../models/AuthDTO'
import { IStatusService } from '../../../common/status/StatusService'
import { IAuthApi } from '../api/AuthApi'
import { AUTH_SERVICE_KEY } from '../container'
import { RegisterDTO } from '../../users/models/RegisterDTO'
import { ChangePasswordDTO } from '../models/ChangePassword'
import { Permission } from '../../../common/enums/Permissions'
import { Error } from '../../users/models/Error'

export interface IAuthService extends IInit {
  login(a: AuthDTO): Observable<LoggedUserDTO | Error>

  logout(): void

  getObservable(): Observable<LoggedUser>

  get(): LoggedUser

  register(r: RegisterDTO): Observable<boolean>

  userCan(perm: string): boolean

  changePassword(a: ChangePasswordDTO): Observable<boolean>

  changePasswordWithToken(a: ChangePasswordDTO, token: string): Observable<boolean>

  recoverPassword(a: { email: string }): Observable<boolean>

  update(): Observable<LoggedUserDTO | undefined>

  switchState(id: string): Observable<boolean>
}

const LOGGED_USER_KEY = 'logged user'

type Props = {
  apiKey: symbol
}

export class AuthService implements IAuthService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IAuthApi
  private _statusService!: IStatusService

  private _user: LoggedUser = toModel(emptyLoggedUserDTO())
  private _userSubject = new Subject<LoggedUser>()

  constructor(p: Props) {
    this.loadUser()
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IAuthApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(AUTH_SERVICE_KEY)
  }

  login(a: AuthDTO): Observable<LoggedUserDTO | Error> {
    const multi = this._api
      .login(a)
      .pipe(multicast(() => new Subject<LoggedUserDTO | Error>())) as ConnectableObservable<
        LoggedUserDTO | Error
      >

    multi.connect()
    multi.subscribe((dto) => {
      if (Object.keys(dto).every((k) => k !== "message")) {
        this.storeUser(dto as LoggedUserDTO)
      }
    })
    return multi
  }

  logout() {
    this._api.logout().subscribe(() => {
      this.removeUser()
    })
  }

  register(r: RegisterDTO): Observable<boolean> {
    return this._api.register(r)
  }

  get(): LoggedUser {
    return this._user
  }

  updateLocalProfile(l: LoggedUserDTO): void {
    localStorage.setItem(LOGGED_USER_KEY, JSON.stringify(l))
    this._user = toModel(l)
    this.next()
  }

  getObservable(): Observable<LoggedUser> {
    return this._userSubject.pipe()
  }

  userCan(perm: Permission): boolean {
    return this._user?.permissions?.includes(perm)
  }

  private next() {
    this._userSubject.next(this._user)
  }

  private loadUser() {
    const dto = (JSON.parse(localStorage.getItem(LOGGED_USER_KEY) || 'null') ||
      undefined) as LoggedUserDTO
    if (dto && new Date(dto.sessionExpires) > new Date()) {
      this._user = toModel(dto)
      this.next()
      setTimeout(() => this.logout(), this._user.sessionExpires.valueOf() - new Date().valueOf())
    }
  }

  private storeUser(dto: LoggedUserDTO) {
    localStorage.setItem(LOGGED_USER_KEY, JSON.stringify(dto))
    this._user = toModel(dto)
    this.next()
    setTimeout(() => this.logout(), this._user.sessionExpires.valueOf() - new Date().valueOf())
  }

  private removeUser() {
    localStorage.removeItem(LOGGED_USER_KEY)
    this._user = toModel(emptyLoggedUserDTO())
    this.next()
  }

  changePassword(a: ChangePasswordDTO): Observable<boolean> {
    return this._api.changePassword(a)
  }

  changePasswordWithToken(a: ChangePasswordDTO, token: string): Observable<boolean> {
    return this._api.changePasswordWithToken(a, token)
  }

  recoverPassword(a: { email: string }): Observable<boolean> {
    return this._api.recoverPassword(a)
  }

  update(): Observable<LoggedUserDTO | undefined> {
    const multi = this._api
      .update()
      .pipe(multicast(() => new Subject<LoggedUserDTO | undefined>())) as ConnectableObservable<
        LoggedUserDTO | undefined
      >

    multi.connect()
    multi.subscribe((dto) => {
      if (dto) {
        this.storeUser(dto)
      }
    })

    return multi
  }

  switchState(id: string): Observable<boolean> {
    return this._api.switchState(id)
  }
}
