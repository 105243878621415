import { Container } from '../../common/container/Container'

export type ReceptivenessFileContainerConfig = {
  moduleFullUrl: string
}

export type ReceptivenessFileProps = {
  parentContainer: Container
  config: ReceptivenessFileContainerConfig
}

export const RECEPTIVENESS_FILE_API_KEY = Symbol('RECEPTIVENESS_FILE_API_KEY')
export const RECEPTIVENESS_FILE_SERVICE_KEY = Symbol('RECEPTIVENESS_FILE_SERVICE_KEY')
export const RECEPTIVENESS_FILES_MODULE = Symbol('RECEPTIVENESS_FILES_MODULE')
