import { Container } from "../../common/container/Container";

export type ScriptContainerConfig = {
  moduleFullUrl: string;
};

export type ScriptProps = {
  parentContainer: Container;
  config: ScriptContainerConfig;
};

export const SCRIPT_EXECUTION_API_KEY = Symbol("SCRIPT_EXECUTION_API_KEY");
export const SCRIPT_EXECUTION_SERVICE_KEY = Symbol("SCRIPT_EXECUTION_SERVICE_KEY");
export const SCRIPT_EXECUTIONS_MODULE = Symbol("SCRIPT_EXECUTIONS_MODULE");
