import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { TITLE_SCRIPT } from '../../routes/title-constants'
import { useTranslation } from 'react-i18next'
import { ScriptForm } from '../../features/scripts/index'

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Title title={t(TITLE_SCRIPT)} color={props.color} />
      <ScriptForm />
    </>
  )
}
