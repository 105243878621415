import { ResultPatientData, ResultPatientDataDTO } from '../../script-executions/models/ResultPatientData'

export interface AnalysisDTO {
  summaryID: string
  matrixID: string
  clinicID: string
  zipFileID: string | undefined
  patients: ResultPatientDataDTO[]
}

export class Analysis {
  private readonly _summaryID: string
  private readonly _matrixID: string
  private readonly _clinicID: string
  private readonly _zipFileID: string | undefined
  private readonly _patients: ResultPatientData[]

  constructor(p: AnalysisDTO) {
    this._summaryID = p.summaryID
    this._matrixID = p.matrixID
    this._clinicID = p.clinicID
    this._zipFileID = p.zipFileID
    this._patients = p.patients.map((p) => new ResultPatientData(p))
  }

  get summaryID(): string {
    return this._summaryID
  }

  get matrixID(): string {
    return this._matrixID
  }

  get clinicID(): string {
    return this._clinicID
  }

  get zipFileID(): string | undefined {
    return this._zipFileID
  }

  get patients(): ResultPatientData[] {
    return this._patients
  }

  toDTO(): AnalysisDTO {
    return {
      summaryID: this.summaryID,
      matrixID: this.matrixID,
      clinicID: this.clinicID,
      zipFileID: this.zipFileID,
      patients: this.patients.map((p) => p.toDTO()),
    }
  }
}

export function toModel(d: AnalysisDTO): Analysis {
  return new Analysis(d)
}
