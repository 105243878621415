import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { Container, IInit } from 'common/container/Container'
import { Observable, of } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { catchError, map } from 'rxjs/operators'
import { Analysis, AnalysisDTO, toModel } from '../models/Analysis'
import { AnalysisContainerConfig } from '../container'

export interface IAnalysisApi extends IInit {
  add(a: AnalysisDTO): Observable<Analysis | undefined>
}

export class AnalysisApi implements IAnalysisApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as AnalysisContainerConfig).moduleFullUrl
  }


  add(a: AnalysisDTO): Observable<any | undefined> {
    return this._httpClient.post<any>({ url: this._url, body: a }).pipe(
      map<any, any>((dto) => {
        if (dto?.zipFileID) {
          return toModel(dto)
        } else {
          const decodedMessage = atob(dto);
          return decodedMessage
        }
        }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }))
  }
}
