import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { Container, IInit } from 'common/container/Container'
import { Observable, of } from 'rxjs'
import { ScriptContainerConfig } from '../../scripts/container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { prepareURL } from '../../../common/api/http-helpers'
import { ScriptExecution, ScriptExecutionDTO, toModel } from '../models/ScriptExecution'

export interface IScriptExecutionApi extends IInit {
  getFilteredList(q: Query<ScriptExecution>): Observable<ItemList<ScriptExecution>>
}

export class ScriptExecutionApi implements IScriptExecutionApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ScriptContainerConfig).moduleFullUrl
  }

  getFilteredList(q: Query<ScriptExecution>): Observable<ItemList<ScriptExecution>> {
    return this._httpClient.get<ItemList<ScriptExecution>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<ScriptExecutionDTO>, ItemList<ScriptExecution>>((dto) => {
        const itemList = emptyList<ScriptExecution>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<ScriptExecution>())
      }),
    )
  }

}
