import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useStyles } from './Receptiveness.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { commonStyles } from '../../common/styles/Styles'
import { ResultPatientDataDTO } from '../../modules/script-executions/models/ResultPatientData'
import { useTranslation } from 'react-i18next'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { EntitySelect } from '../common/EntitySelect'
import {
  TestCycle,
  testCycleErrors,
  TestCycleErrors,
  testCycles,
} from '../../modules/tests/enums/TestCycle'

type SecondStepProps = {
  increaseStep: () => void
  values: ResultPatientDataDTO[]
  updateValues: (v: ResultPatientDataDTO[]) => void
  deleteFiles: () => void
}

const excludedFields = ['barcodeID', 'sampleName']

const dateFields: (keyof ResultPatientDataDTO)[] = [
  'biopsyDate',
  'p4Injection',
  'hcgInjection',
  'lhDate',
]

const selectFields: (keyof ResultPatientDataDTO)[] = ['cycleType']

const numberFields: (keyof ResultPatientDataDTO)[] = ['biopsyNumber', 'pPlus']

type Cycle = {
  label: string
  value: TestCycle
}

const options: Cycle[] = Object.entries(testCycles())
  .filter((k) => +k[0] !== TestCycle.NotApplied)
  .map((k) => ({
    label: k[1],
    value: +k[0],
  }))

export const SecondStep = (props: SecondStepProps) => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const styles = commonStyles()
  const { t } = useTranslation()
  const [clearedDate, setClearedDate] = useState<Date | null>(null)
  const [secondStep, setSecondStep] = useState<ResultPatientDataDTO[]>(props.values)
  const [open, setOpen] = useState<TestCycleErrors>(TestCycleErrors.NoError)

  const changeValue = (barcodeID: string, key: string, value: Date | number | string) => {
    if (numberFields.filter((n) => n != "pPlus").includes(key as keyof ResultPatientDataDTO) && (+value < 1 || +value > 2)) {
      return
    }
    const index = secondStep.findIndex((s) => s.barcodeID === barcodeID)
    if (index === -1) {
      return
    }
    const result = [...secondStep]
    result.splice(index, 1, Object.assign({ ...secondStep[index] }, { [key]: value }))
    setSecondStep(result)
  }

  const validateNew = () => {
    let bad = false
    secondStep.map((s, i) => {
      if (s.cycleType == TestCycle.Substituted) {
        if (!s.biopsyDate || !s.biopsyNumber || !s.p4Injection || !s.biopsyMethod || !s.pPlus) {
          bad = true
          setOpen(TestCycleErrors.Substituted)
          return
        } else {
          props.updateValues(secondStep)
        }
      } else if (s.cycleType == TestCycle.Natural) {
        if (!s.biopsyDate || !s.biopsyNumber || !s.biopsyMethod) {
          bad = true
          setOpen(TestCycleErrors.Natural)
          return
        } else {
          props.updateValues(secondStep)
        }
      } else {
        bad = true
        setOpen(TestCycleErrors.NotApplied)
        return
      }

      if (i == secondStep.length - 1 && !bad) {
        props.increaseStep()
      }
    })
  }

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            {Object.keys(secondStep[0]).map((k) => (
              <TableCell
                className={
                  !excludedFields.includes(k)
                    ? classes.blackBottomBorder + ' ' + classes.fixedCell
                    : classes.fixedCell
                }>
                {t(k)}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {secondStep.map((s) => (
              <TableRow>
                <TableCell key={'barcodeID'} className={classes.fixedCell}>
                  {s.barcodeID}
                </TableCell>
                <TableCell
                  key={'sampleName'}
                  className={classes.fixedCell + ' ' + classes.sampleName}>
                  {s.sampleName}
                </TableCell>
                {Object.entries(s)
                  .filter((k) => !excludedFields.includes(k[0]))
                  .map((k) => (
                    <TableCell key={k[0]} className={classes.innerCell}>
                      {dateFields.includes(k[0] as keyof ResultPatientDataDTO) && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            id={k[0]}
                            autoOk
                            inputVariant={'standard'}
                            format="dd/MM/yyyy HH:mm"
                            value={k[1] ? k[1] : clearedDate}
                            onChange={(newDate) =>
                              newDate && changeValue(s.barcodeID, k[0], newDate)
                            }
                            required={true}
                            size={'small'}
                            invalidDateMessage={''}
                            InputProps={{ disableUnderline: true }}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                      {selectFields.includes(k[0] as keyof ResultPatientDataDTO) && (
                        <EntitySelect
                          name={'label'}
                          value={k[1]}
                          options={options}
                          onChange={(e) => e && changeValue(s.barcodeID, k[0], e.value)}
                          pk={'value'}
                          variant={'standard'}
                        />
                      )}
                      {numberFields.includes(k[0] as keyof ResultPatientDataDTO) && (
                        <>
                          <TextField
                            name={k[0]}
                            value={k[1]}
                            type={'number'}
                            onChange={(e) => {
                              if (k[0] == 'pPlus') {
                                changeValue(s.barcodeID, k[0], String(+e.target.value))
                              } else {
                                changeValue(s.barcodeID, k[0], +e.target.value)
                              }
                            }}
                            InputProps={{
                              disableUnderline: true,
                              inputProps: { min: 1.0, step: '.01' },
                            }}
                          />
                        </>
                      )}
                      {!dateFields.includes(k[0] as keyof ResultPatientDataDTO) &&
                        !selectFields.includes(k[0] as keyof ResultPatientDataDTO) &&
                        !numberFields.includes(k[0] as keyof ResultPatientDataDTO) && (
                          <TextField
                            name={k[0]}
                            value={k[1]}
                            onChange={(e) => changeValue(s.barcodeID, k[0], e.target.value)}
                            InputProps={{ disableUnderline: true }}
                          />
                        )}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={open != TestCycleErrors.NoError}>
        <Box className={styles.modal}>
          <Box mt={2} mb={3} textAlign={'justify'}>
            {testCycleErrors()[open]}
          </Box>
          <Box mb={3}>
            <Button
              onClick={() => {
                setOpen(TestCycleErrors.NoError)
              }}>
              {t('close')}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Grid item xs={12}>
        <Box mt={3}>
          <Button onClick={validateNew}>{t('continue')}</Button>
          <Button onClick={props.deleteFiles}>{t('cancel')}</Button>
        </Box>
      </Grid>
    </>
  )
}
