import { Box, Button, Grid, Modal, Step, StepLabel, Stepper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React, { FormEvent, useState } from 'react'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'
import { navigate } from '@reach/router'
import { useStyles } from 'features/receptiveness/Receptiveness.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { URL_RECEPTIVENESS_TABLE } from '../../routes/routes-constants'
import { ResultPatientDataDTO } from '../../modules/script-executions/models/ResultPatientData'
import { FirstStep } from './FirstStep'
import { SecondStep } from './SecondStep'
import { forkJoin } from 'rxjs'
import { getFileContainer } from '../../container/file-module'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { FileService } from '../../modules/files/services/FileService'
import { ThirdStep } from './ThirdStep'
import { Analysis } from '../../modules/analysis/models/Analysis'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { commonStyles } from '../../common/styles/Styles'

const steps = ['checks', 'completeData', 'analysis']

export type FileType = {
  id: string
  name: string
}

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

export const Form = () => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const styles = commonStyles()

  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState<number>(0)
  const [resultPatientList, setResultPatientList] = useState<ResultPatientDataDTO[]>([])
  const [fileTypes, setFileTypes] = useState<FileType[]>([])
  const [selectedClinic, setSelectedClinic] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)

  const goToTable = () => navigate(URL_RECEPTIVENESS_TABLE)

  const increaseStep = () => setCurrentStep(currentStep + 1)

  const deleteFiles = (e: FormEvent) => {
    e.preventDefault()
    forkJoin(
      fileTypes.map((f) => fileService.delete(f.id)),
    ).subscribe(() => {
      setOpen(false)
      setCurrentStep(0)
    })
  }
  const actionsDelete: FormAction[] = [
    {
      label: t('close'),
      handleAction: () => setOpen(false),
    },
  ]

  return (
    <Grid container spacing={2}>
      {authService.userCan(Permission.seeResultsRegister) && (
        <Grid item xs={12}>
          <Button
            className={classes.button}
            onClick={goToTable}
            variant={'contained'}
            style={{ float: 'right' }}>
            {t('seeRegister')}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stepper activeStep={currentStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      {currentStep === 0 && (
        <FirstStep
          increaseStep={increaseStep}
          files={setFileTypes}
          valuesToTable={setResultPatientList}
          selectedClinic={setSelectedClinic}
        />
      )}

      {currentStep === 1 && resultPatientList.length > 0 && (
        <SecondStep
          increaseStep={increaseStep}
          values={resultPatientList}
          updateValues={setResultPatientList}
          deleteFiles={() => setOpen(true)}
        />
      )}
      {currentStep === 2 && (
        <ThirdStep
          analysis={new Analysis({
            summaryID: fileTypes.find((f) => f.name.endsWith('bc_summary.xls'))?.id || '',
            matrixID: fileTypes.find((f) => f.name.endsWith('bcmatrix.xls'))?.id || '',
            zipFileID: undefined,
            clinicID: selectedClinic,
            patients: resultPatientList,
          })}
          resetSteps={() => setCurrentStep(0)}
        />
      )}
      <Modal open={open}>
        <Box className={styles.modal}>
          <form onSubmit={deleteFiles}>
            <Box mt={2} textAlign={'justify'}>
              {t('cancelReceptiveness')}
            </Box>
            <FormActions actions={actionsDelete} message={t('confirm')} />
          </form>
        </Box>
      </Modal>
    </Grid>
  )
}