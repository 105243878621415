import i18n from '../../../i18n/i18n'

export enum TestCycle {
    Natural = 1,
    Substituted,
    NotApplied = 100,
}

export const testCycles = (): Record<TestCycle, string> => ({
    [TestCycle.Natural]: i18n.t('natural'),
    [TestCycle.Substituted]: 'HRT',
    [TestCycle.NotApplied]: i18n.t('notApplied'),
})

export enum TestCycleErrors {
    NoError = 0,
    Natural = 1,
    Substituted,
    NotApplied = 100,
}

export const testCycleErrors = (): Record<TestCycleErrors, string> => ({
    [TestCycleErrors.Natural]: i18n.t('fillAllFieldsErrorNatural'),
    [TestCycleErrors.Substituted]: i18n.t('fillAllFieldsErrorSubstituted'),
    [TestCycleErrors.NotApplied]: i18n.t('fillAllFieldsError'),
    [TestCycleErrors.NoError]: "",
})
