import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Script, ScriptDTO } from '../models/Script'
import { IScriptApi } from '../api/ScriptApi'

type Props = {
  apiKey: symbol
}

export interface IScriptService extends IInit {
  getCurrentScript(): Observable<Script | undefined>
  create(p: ScriptDTO): Observable<Script | undefined>
  update(p: ScriptDTO): Observable<Script | undefined>
}

export class ScriptService implements IScriptService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IScriptApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IScriptApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getCurrentScript(): Observable<Script | undefined> {
    return this._api.getCurrentScript()
  }

  create(p: ScriptDTO): Observable<Script | undefined> {
    return this._api.create(p)
  }

  update(p: ScriptDTO): Observable<Script | undefined> {
    return this._api.update(p)
  }

}
