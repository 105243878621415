import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { SCRIPT_API_KEY, SCRIPT_SERVICE_KEY, ScriptProps, SCRIPTS_MODULE } from '../modules/scripts/container'
import { ScriptService } from '../modules/scripts/services/ScriptService'
import { ScriptApi } from '../modules/scripts/api/ScriptApi'

let container: Container

function init(p: ScriptProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(SCRIPT_API_KEY, new ContainerItem({ instance: new ScriptApi() }))
  items.set(
    SCRIPT_SERVICE_KEY,
    new ContainerItem({
      instance: new ScriptService({ apiKey: SCRIPT_API_KEY }),
    }),
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getScriptContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[SCRIPTS_MODULE],
      },
    })
  }

  return container
}