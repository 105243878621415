import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { IScriptExecutionApi } from '../api/ScriptExecutionApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { ScriptExecution } from '../models/ScriptExecution'

type Props = {
  apiKey: symbol
}

export interface IScriptExecutionService extends IInit {
  getFilteredList(q: Query<ScriptExecution>): Observable<ItemList<ScriptExecution>>
}

export class ScriptExecutionService implements IScriptExecutionService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IScriptExecutionApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IScriptExecutionApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getFilteredList(q: Query<ScriptExecution>): Observable<ItemList<ScriptExecution>> {
    return this._api.getFilteredList(q)
  }
}
