import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { AnalysisService } from '../modules/analysis/services/AnalysisService'
import { AnalysisApi } from '../modules/analysis/api/AnalysisApi'
import { ScriptProps } from '../modules/scripts/container'
import { ANALYSIS_API_KEY, ANALYSIS_MODULE, ANALYSIS_SERVICE_KEY } from '../modules/analysis'

let container: Container

function init(p: ScriptProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(ANALYSIS_API_KEY, new ContainerItem({ instance: new AnalysisApi() }))
  items.set(
    ANALYSIS_SERVICE_KEY,
    new ContainerItem({
      instance: new AnalysisService({ apiKey: ANALYSIS_API_KEY }),
    }),
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getAnalysisContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[ANALYSIS_MODULE],
      },
    })
  }

  return container
}