import {COLOR_PATIENTS, COLOR_PRIMARY} from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager, Sort } from '../../components/table/types'
import { ScriptExecution } from '../../modules/script-executions/models/ScriptExecution'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { getScriptExecutionContainer } from '../../container/script-executions-module'
import { SCRIPT_EXECUTION_SERVICE_KEY } from '../../modules/script-executions'
import { ScriptExecutionService } from '../../modules/script-executions/services/ScriptExecutionService'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import { getFileContainer } from 'container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { downloadFile } from '../../common/files/file'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { getClinicContainer } from '../../container/clinic-modules'
import { getUserContainer } from 'container/user-modules'
import { UserService } from '../../modules/users/services/UserService'
import { USER_SERVICE_KEY } from '../../modules/users'
import { ClinicQuery } from '../../modules/clinics/models/Clinic'
import { UserQuery } from '../../modules/users/models/User'

const scriptExecutionService = getScriptExecutionContainer().get<ScriptExecutionService>(SCRIPT_EXECUTION_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export const Table = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<ScriptExecution[]>([])
  const [clinics, setClinics] = useState<Map<string, string>>(new Map<string, string>())
  const [users, setUsers] = useState<Map<string, string>>(new Map<string, string>())
  const [sort, setSort] = useState<SortParam<ScriptExecution>>({
    field: 'date',
    desc: true,
  })

  useEffect(() => {
    if (!isLoading) {
      return
    }
    scriptExecutionService.getFilteredList(new Query({
      pager: { offset: page * itemsPerPage, limit: itemsPerPage },
      sort: [{ field: sort.field, desc: sort.desc }],
    })).subscribe((res) => {
      setIsLoading(false)
      setItems(res.items)
      setCount(res.count)
    })
  }, [isLoading])

  useEffect(() => {
    if (!items.length) {
      return
    }
    clinicService.getFilteredList(new Query({
      pager: {
        offset: 0,
        limit: items.length,
      },
      query: [new QueryParam<ClinicQuery>('ids', items.map((i) => i.clinicID))],
    })).subscribe((cl) => {
      const newMap = new Map<string, string>()
      cl.items.forEach((c) => c.id && newMap.set(c.id, c.name))
      setClinics(newMap)
    })
    userService.getFilteredList(new Query({
      pager: {
        offset: 0,
        limit: items.length,
      },
      query: [new QueryParam<UserQuery>('ids', items.map((i) => i.userID))],
    })).subscribe((ul) => {
      const newMap = new Map<string, string>()
      ul.items.forEach((u) => u.id && newMap.set(u.id, u.firstName + ' ' + u.lastName))
      setUsers(newMap)
    })
  }, [items])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<ScriptExecution>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('date'),
      name: 'date',
      renderFunc: (f, i) => new Date(i.date).toLocaleDateString(),
    },
    {
      searchable: true,
      sortable: true,
      label: t('clinic'),
      name: 'clinicID',
      renderFunc: (f, i) => clinics.get(i.clinicID) || '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('user'),
      name: 'userID',
      renderFunc: (f, i) => users.get(i.userID) || '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('success'),
      name: 'success',
      renderFunc: (f, i) => t('' + i.success),
    },
  ]

  const downloadZip = (s: ScriptExecution) =>
    fileService.getByID(s.zipFileID).subscribe((res) =>
      res && downloadFile(res.name, res.mimeType, res.data))

  const hideIcon = (s: ScriptExecution) => !s.zipFileID

  const actions: Actions<ScriptExecution> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: downloadZip,
        icon: downloadIcon,
        label: t('Download'),
        hidden: hideIcon,
      },
    ],
  }

  const sortable: Sort<ScriptExecution> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  return (
    <AppTable
      styleHeader={{ color: COLOR_PRIMARY }}
      actions={actions}
      fields={fields}
      items={items}
      rowKeyField={'id'}
      pager={pager}
      sort={sortable}
    />
  )
}