import { Container } from "../../common/container/Container";

export type AnalysisContainerConfig = {
  moduleFullUrl: string;
};

export type AnalysisProps = {
  parentContainer: Container;
  config: AnalysisContainerConfig;
};

export const ANALYSIS_API_KEY = Symbol("ANALYSIS_API_KEY");
export const ANALYSIS_SERVICE_KEY = Symbol("ANALYSIS_SERVICE_KEY");
export const ANALYSIS_MODULE = Symbol("ANALYSIS_MODULE");
