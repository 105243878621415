import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { FILE_API_KEY, FILE_SERVICE_KEY } from '../modules/files'
import { FileProps, FILES_MODULE } from '../modules/files/container'
import { FileService } from '../modules/files/services/FileService'
import { FileApi } from '../modules/files/api/FileApi'
import {
  RECEPTIVENESS_FILE_API_KEY,
  RECEPTIVENESS_FILE_SERVICE_KEY,
  RECEPTIVENESS_FILES_MODULE,
} from '../modules/receptiveness-files/container'
import { ReceptivenessFileApi } from '../modules/receptiveness-files/api/ReceptivenessFilesApi'
import { ReceptivenessFileService } from '../modules/receptiveness-files/services/ReceptivenessFileService'

let container: Container

function init(p: FileProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(RECEPTIVENESS_FILE_API_KEY, new ContainerItem({ instance: new ReceptivenessFileApi() }))
  items.set(
    RECEPTIVENESS_FILE_SERVICE_KEY,
    new ContainerItem({
      instance: new ReceptivenessFileService({ apiKey: RECEPTIVENESS_FILE_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getReceptivenessFileContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[RECEPTIVENESS_FILES_MODULE],
      },
    })
  }

  return container
}
