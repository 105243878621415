import { Container } from "../../common/container/Container";

export type ScriptContainerConfig = {
    moduleFullUrl: string;
};

export type ScriptProps = {
    parentContainer: Container;
    config: ScriptContainerConfig;
};

export const SCRIPT_API_KEY = Symbol("SCRIPT_API_KEY");
export const SCRIPT_SERVICE_KEY = Symbol("SCRIPT_SERVICE_KEY");
export const SCRIPTS_MODULE = Symbol("SCRIPTS_MODULE");
