
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ReceptivenessFile, ReceptivenessFileDTO } from '../models/ReceptivenessFile'
import { IReceptivenessFileApi } from '../api/ReceptivenessFilesApi'

type Props = {
  apiKey: symbol
}

export interface IReceptivenessFileService extends IInit {
  getByID(id: string): Observable<ReceptivenessFile | undefined>
  getFilteredList(q: Query<ReceptivenessFile>): Observable<ItemList<ReceptivenessFile>>
  add(e: ReceptivenessFileDTO): Observable<ReceptivenessFile | undefined>
  update(e: ReceptivenessFileDTO): Observable<ReceptivenessFile | undefined>
  delete(id: string): Observable<boolean>
  getCurrentScriptByID(id: string): Observable<ReceptivenessFile | undefined>
}

export class ReceptivenessFileService implements IReceptivenessFileService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IReceptivenessFileApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IReceptivenessFileApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string): Observable<ReceptivenessFile | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<ReceptivenessFile>): Observable<ItemList<ReceptivenessFile>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: ReceptivenessFileDTO): Observable<ReceptivenessFile | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: ReceptivenessFileDTO): Observable<ReceptivenessFile | undefined> {
    return this._api.update(e)
  }

  getCurrentScriptByID(id: string): Observable<ReceptivenessFile | undefined>{
    return this._api.getCurrentScriptByID(id)
  }
}
